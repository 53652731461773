import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useSelector } from "react-redux";
import apiService from "../../services/api.service";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Loader from "../loader/Loader";

const PayPalCheckoutForm = () => {
    const navigate = useNavigate()
    const amount = useSelector((state) => state.cart.sum)
    const passengers = useSelector((state) => state.cart.passengers)
    const cargo = useSelector((state) => state.cart.cargo.items)
    const sender = useSelector((state) => state.cart.cargo.sender)
    const receiver = useSelector((state) => state.cart.cargo.receiver)
    const trip = useSelector((state) => state.cart.trip)
    const type = useSelector((state) => state.search.type)

    const [error, setError] = useState(false)
    //FOR DEMO: AVJDju2FR9X8KnsWyPZyr7Ndvw5joq9-T2dEW8ANCFuvKH7bsXj6srugU7FtkzYbcc8tp0TNoWRVyPJe
    const initialOptions = {
        clientId: "ASijAk9SKKG79YUoZE1MTeJTH0z9CVkOyZwQsTOY4_CiLGY6ELbAIdtZvkuAxqX2fGzcDVe5NVXx4_90",
        currency: "EUR",
        intent: "capture",
        commit: true,
    };
    const roundedAmount = amount.toFixed(2)

    function createOrder(data, actions) {
        try {
            return actions.order.create({
                purchase_units: [
                    {
                        amount: {
                            currency_code: "EUR",
                            value: roundedAmount
                        },
                        description: "BusLines - Ticket"
                    }
                ]
            });
        } catch (error) {
            console.log(error)
        }
    }

    function onError(err){
        setError(true)
        console.log(err)
    }

    function onCancel(err){
        setError(true)
        console.log(err)
    }

    async function onApprove(data, actions) {
        const order = await actions.order.capture()
        if (type == "tickets") {
            if (order && passengers) {
                try {
                    const res = await apiService.passengerAdd({ passengers });
                    if (res) {
                        const payment = await apiService.payTickets({
                            tickets: passengers.map((passenger) => ({ ticketId: passenger.ticketId })),
                            transactionId: data.orderID,
                            method: "paypal",
                            sum: order.purchase_units[0].amount.value
                        })
                        if (payment.data.success) {
                            navigate("/payment/success")
                        }
                        else {
                            navigate("/payment/error")
                        }
                    }
                    else {
                        navigate("/payment/error")
                    }
                } catch (error) {
                    console.log(error)
                    navigate("/payment/error")
                }
            }
        } else {
            if (order && cargo) {
                try {
                    const senderReceiver = await apiService.cargoPassengerAdd({ passengers: [{ ...sender, kind: "" }, { ...receiver, kind: "" }] });
                    if (senderReceiver) {
                        const payment = await apiService.payCargo({
                            transactionId: data.orderID,
                            method: "paypal",
                            sum: order.purchase_units[0].amount.value
                        })

                        if (payment) {
                            const cargoTickets = await apiService.cargoTicketAdd({
                                tickets: cargo.map((item) => ({
                                    departureTripId: trip.departureTripId,
                                    arrivalTripId: trip.arrivalTripId,
                                    routeId: trip.routeId,
                                    senderId: senderReceiver.data[0].id,
                                    receiverId: senderReceiver.data[1].id,
                                    paymentId: payment.data.id,
                                    categoryId: item.type,
                                    weight: item.weight,
                                    height: item.height,
                                    length: item.length,
                                    width: item.width
                                }))
                            });
                            if (cargoTickets.data) {
                                navigate("/payment/success")
                            }
                            else navigate("/payment/error")
                        }
                        else {
                            navigate("/payment/error")
                        }
                    }
                    else {
                        navigate("/payment/error")
                    }
                } catch (error) {
                    console.log(error)
                    navigate("/payment/error")
                }
            }
        }
    }

    return (<>
        <PayPalScriptProvider options={initialOptions}>
            <PayPalButtons
                createOrder={createOrder}
                onApprove={onApprove}
                onError={onError}
                onCancel={onCancel}
            />
        </PayPalScriptProvider>
    </>);
}
export default PayPalCheckoutForm;
