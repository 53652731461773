
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import 'react-international-phone/style.css';
import { useDispatch, useSelector } from "react-redux";
import { editReceiver, editSender } from "../../../slices/cart";
import "../common.css"
import moment from "moment";
import Sender from "./senderreceiver/Sender";
import Receiver from "./senderreceiver/Receiver";
import { isPossiblePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js'
import { CheckOutlined, ExclamationOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const SenderReceiver = ({ setSubmittable, trigger }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [sDay, setSDay] = useState('');
    const [sMonth, setSMonth] = useState('');
    const [sYear, setSYear] = useState('');

    const [rDay, setRDay] = useState('');
    const [rMonth, setRMonth] = useState('');
    const [rYear, setRYear] = useState('');

    const [sCountry, setSCountry] = useState('md')
    const [rCountry, setRCountry] = useState('md')

    const [srState, setSrState] = useState(0)

    const sender = useSelector((state) => state.cart.cargo.sender)
    const receiver = useSelector((state) => state.cart.cargo.receiver)

    function checkSubmittable(object, country) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(object.email) && isPossiblePhoneNumber(object.phone, country) && isValidPhoneNumber(object.phone, country) && !Object.values(object).some(item => item === null || item === '' || item === undefined)) {
            return (true)
        }
        return (false)
    }

    function checkValidity(object, country) {
        if (trigger && !checkSubmittable(object, country)) {
            return true
        }
        return false
    }

    useEffect(() => {
        if (checkSubmittable(sender, sCountry) && checkSubmittable(receiver, rCountry)) {
            setSubmittable(true)
        } else {
            checkValidity(sender, sCountry) && checkValidity(receiver, rCountry)
            setSubmittable(false)
        }
    }, [sender, receiver])

    useEffect(() => {
        const handleSenderBirthday = () => {
            if (sDay && sMonth && sYear) {
                const convertDate = moment(`${sDay}-${sMonth}-${sYear}`, "DD-MM-YYYY");
                dispatch(editSender({
                    key: 'birthday',
                    value: convertDate.isValid() && convertDate.toString()
                }))
            }
        }
        const handleReceiverBirthday = () => {
            if (rDay && rMonth && rYear) {
                const convertDate = moment(`${rDay}-${rMonth}-${rYear}`, "DD-MM-YYYY");
                dispatch(editReceiver({
                    key: 'birthday',
                    value: convertDate.isValid() && convertDate.toString()
                }))
            }
        }
        srState == 0 ? handleSenderBirthday() : handleReceiverBirthday()
    }, [sDay, rDay, sMonth, rMonth, sYear, rYear])

    const sr = [{ id: 0, label: t("sender") }, { id: 1, label: t("receiver") }]

    const currentYear = new Date().getFullYear();

    return (
        <Container className="sr-outer">
            <div className="flex sr-manual">
                {sr.map((x, i) =>
                    <button key={i} onClick={() => setSrState(x.id)} className={`cargo-tab-item font-orange ${x.id == 0 && "left-border"} ${srState == x.id ? "bg-var-orange" : null} ${x.id == sr.length - 1 && "right-border"}`}>
                        {x.label}
                        {i === 0 ? checkSubmittable(sender, sCountry) ? <span className={`cargo-tab-tick left cargo-tab-tick-appear`}><CheckOutlined /></span> : <span className="cargo-tab-tick-disappear left"><CheckOutlined /></span> : null}
                        {i === 0 ? checkValidity(sender, sCountry) ? <span className={`cargo-tab-tick left cargo-tab-tick-appear bg-danger`}><ExclamationOutlined /></span> : <span className="cargo-tab-tick-disappear left"><ExclamationOutlined /></span> : null}
                        {i === 1 ? checkSubmittable(receiver, rCountry) ? <span className={`cargo-tab-tick cargo-tab-tick-appear`}><CheckOutlined /></span> : <span className="cargo-tab-tick-disappear"><CheckOutlined /></span> : null}
                        {i === 1 ? checkValidity(receiver, rCountry) ? <span className={`cargo-tab-tick cargo-tab-tick-appear bg-danger`}><ExclamationOutlined /></span> : <span className="cargo-tab-tick-disappear"><ExclamationOutlined /></span> : null}
                    </button>
                )
                }
            </div>
            {srState == 0
                ? <Sender setSDay={setSDay} setSYear={setSYear} setSMonth={setSMonth} currentYear={currentYear} sCountry={sCountry} setSCountry={setSCountry} trigger={trigger} sYear={sYear} sMonth={sMonth} sDay={sDay} />
                : <Receiver setRDay={setRDay} setRYear={setRYear} setRMonth={setRMonth} currentYear={currentYear} rCountry={rCountry} setRCountry={setRCountry} trigger={trigger} rYear={rYear} rMonth={rMonth} rDay={rDay} />}
        </Container>
    );
}
export default SenderReceiver;
