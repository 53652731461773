import React from 'react'
import SliderMain from '../slider/SliderMain'
import "../resendticket/resendticket.css"
import "./transaction.css"
import busstop from "../../assets/img/bus_stop_image.png"
import bus from "../../assets/img/bus_image.png"
import { ReactComponent as ErrorImage } from "../../assets/img/icon_error.svg"
import { Image, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const TransactionError = () => {
  const { t } = useTranslation()
  return (
    <>
      <SliderMain />
      <Container className='resend-main'>
        <div className='resend-ticket-info'>
          <div className='resend-ticket-info-wrapper'>
            <div className='d-flex flex-column justify-content-end transaction-result-wrapper'>
              <div className='d-flex align-items-center justify-content-center gap-3 position-relative'>
                <ErrorImage height={36} className="transaction-result-icon" />
                <div className='transaction-result-border-b text-center'>
                  <h3>
                    {t("transactionError")}
                    {/* transaction error */}
                  </h3>
                </div>
              </div>
              <div className='align-self-end'>
                <p className='error'>
                  {/* Something went wrong, please verify your
                  data and try again. */}
                  {t("smthWentWrong")}
                </p>
              </div>
            </div>
            <div className='resend-image-container'>
              <Image src={bus} className='resend-image-bus' />
              <Image src={busstop} className='resend-image' />
            </div>
            <div className='transaction-result-btn-container'>
              <Link to={"/"} className='transaction-result-btn'>
                {/* Try Again */}
                {t("tryAgain")}
              </Link>
            </div>
          </div>

        </div>
      </Container></>
  )
}

export default TransactionError