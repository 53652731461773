import React, { useEffect, useState } from "react";
import { Divider, Form, Input, InputNumber, Select, Switch } from "antd";
import { Col, Row } from "react-bootstrap";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { useDispatch, useSelector } from "react-redux";
import { editReceiver } from "../../../../slices/cart";
import { ReactComponent as EmailImg } from '../../../../assets/img/icon_mail.svg';
import "../../common.css";
import moment from "moment";
import { isPossiblePhoneNumber, isValidPhoneNumber, validatePhoneNumberLength } from "libphonenumber-js";
import { useTranslation } from "react-i18next";


const Receiver = ({ setRDay, setRMonth, setRYear, currentYear, setRCountry, rCountry, trigger, rDay, rMonth, rYear }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [receiverPhone, setReceiverPhone] = useState('')
    const [receiverValid, setReceiverValid] = useState(true)
    const receiver = useSelector((state) => state.cart.cargo.receiver)

    useEffect(() => {
        if (isPossiblePhoneNumber(receiver.phone, rCountry) && validatePhoneNumberLength(receiver.phone, rCountry) && isValidPhoneNumber(receiver.phone, rCountry)) {
            setReceiverValid(true)
        }
        else { setReceiverValid(false) }
    }, [receiver.phone, rCountry, receiverValid])

    const [receiverForm] = Form.useForm();
    const options = [
        {
            value: "female",
            label: t("female"),
        },
        {
            value: "male",
            label: t('male'),
        }
    ]

    const downOutlinedIcon = () => {
        return (
            <span className='down-outlined-icon'></span>
        )
    }
    return (
        <Form
            name="receiverForm"
            style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}
            className="common-form"
            layout="vertical" autoComplete="off"
            form={receiverForm}
        >

            <div style={{ width: "100%" }}>
                <Col style={{ width: "100%" }}>
                    <Row style={{ width: "100%" }}>
                        <Col md={8}>

                            <Form.Item
                                validateStatus={trigger && !receiver.name ? "error" : "success"} help={trigger && !receiver.name ? t("nameValidation") : ""}
                                initialValue={receiver?.name}
                                name="name" label={t("name")}
                            // rules={[{ required: true, message: 'Please input your name!' }]}
                            >
                                <Input placeholder={t("name")} onChange={(e) => dispatch(editReceiver({
                                    key: "name", value: e.target.value
                                }))} />
                            </Form.Item>

                        </Col>
                    </Row>
                    <Row style={{ width: "100%" }}>
                        <Col md={8}>

                            <Form.Item
                                validateStatus={trigger && !receiver.surname ? "error" : "success"} help={trigger && !receiver.surname ? t("surnameValidation") : ""}
                                initialValue={receiver?.surname}
                                name="surname" label={t("surname")}
                            // rules={[{ required: true, message: 'Please input your surname!' }]}
                            >
                                <Input placeholder={t("surname")} onChange={(e) => dispatch(editReceiver({
                                    key: "surname", value: e.target.value
                                }))} />
                            </Form.Item>

                        </Col>
                        <Col md={4}>

                            <Form.Item
                                name="gender" initialValue={receiver?.gender ? receiver?.gender : "Male"} label={t("gender")} validateStatus={trigger && !receiver.gender ? "error" : "success"} help={trigger && !receiver.gender ? t("genderValidation") : ""}
                            // rules={[
                            //     {
                            //         required: true, message: 'Please choose your gender!'
                            //     },
                            // ]}
                            >
                                <Select
                                    suffixIcon={downOutlinedIcon()}
                                    onChange={(value) => dispatch(editReceiver({
                                        key: "gender", value
                                    }))}
                                    style={{
                                        width: "100%",
                                    }}
                                    options={options}
                                />
                            </Form.Item>

                        </Col>
                    </Row>
                    <Row style={{ width: "100%" }} className="align-items-end">

                        <Col md={4}>

                            <Form.Item
                                // rules={[
                                //     {
                                //         required: true, message: 'Please input your day of birth!'

                                //     },
                                // ]} 
                                initialValue={+moment(receiver?.birthday).format('D')} name="day" label={t("dob")} validateStatus={trigger && (!rDay && !receiver?.birthday) ? "error" : "success"} help={trigger && (!rDay && !receiver?.birthday) ? t("dobValidation") : ""}>
                                <InputNumber placeholder="DD" style={{ width: "100%" }} onChange={(value) => setRDay(value)} min={1} max={31} type='number' />
                            </Form.Item>

                        </Col>

                        <Col md={4}>

                            <Form.Item
                                // rules={[
                                //     {
                                //         required: true, message: 'Please input your month of birth!'
                                //     },
                                // ]}
                                initialValue={+moment(receiver?.birthday).format('M')} name="month" validateStatus={trigger && (!rMonth && !receiver?.birthday) ? "error" : "success"} >
                                <InputNumber placeholder="MM" style={{ width: "100%" }} onChange={(value) => setRMonth(value)} min={1} max={12} type='number' />
                            </Form.Item>

                        </Col>

                        <Col md={4}>

                            <Form.Item
                                // rules={[
                                //     {
                                //         required: true, message: 'Please input your year of birth!'
                                //     },
                                // ]}
                                initialValue={+moment(receiver?.birthday).format('YYYY')} name="year" validateStatus={trigger && (!rYear && !receiver?.birthday) ? "error" : "success"} >
                                <InputNumber placeholder="YYYY" style={{ width: "100%" }} onChange={(value) => setRYear(value)} min={1920} max={currentYear} type='number' />
                            </Form.Item>

                        </Col>

                    </Row>
                    <Row style={{ width: "100%" }}>
                        <Col md={6}>

                            <Form.Item
                                // rules={[
                                //     {
                                //         required: true, message: 'Please input your passport ID!'
                                //     },
                                // ]}
                                initialValue={receiver?.passport} name="passport" label={t("passport")} validateStatus={trigger && !receiver.passport ? "error" : "success"} help={trigger && !receiver.passport ? t("passportValidation") : ""}>
                                <Input placeholder={t("passport")} onChange={(e) => dispatch(editReceiver({
                                    key: "passport", value: e.target.value
                                }))} />
                            </Form.Item>

                        </Col>
                        <Col md={6}>

                            <Form.Item initialValue={receiver?.phone} validateStatus={"error"} help={trigger && !receiverValid ? t("phoneValidation") : ''}
                                // rules={[
                                //     {
                                //         required: true, message: 'Please input your phone!'
                                //     },
                                // ]}
                                name="phone" label={t("mphone")}>
                                <PhoneInput
                                    className={trigger && !receiverValid ? 'phone-not-valid' : ''}
                                    placeholder="+123 (12) 34-56-78"
                                    defaultCountry='md'
                                    dropdownArrowStyle={{ display: 'none' }}
                                    value={receiver?.phone}
                                    onChange={(phone, country) => {
                                        setRCountry(country)
                                        setReceiverPhone(phone)
                                        dispatch(editReceiver({
                                            key: "phone", value: phone
                                        }))
                                    }}
                                />
                                {/* {!isReceiverValid && <div style={{ color: 'red' }}>Phone is not valid</div>} */}
                            </Form.Item>

                        </Col>
                    </Row>
                    <Row style={{ width: "100%" }}>
                        <Col md={6}>

                            <Form.Item
                                // rules={[
                                //     {
                                //         required: true, message: 'Please input your e-mail!'
                                //     },
                                //     {
                                //         type: "email", message: 'Incorrect e-mail format!'
                                //     }
                                // ]} 
                                initialValue={receiver?.email} name="email" label={t("email")} validateStatus={trigger && !receiver.email ? "error" : "success"} help={trigger && !receiver.email ? t("emailValidation") : ""}>
                                <Input placeholder={t("email")} onChange={(e) => dispatch(editReceiver({
                                    key: "email", value: e.target.value
                                }))} />
                            </Form.Item>

                        </Col>

                    </Row>
                </Col>
            </div>
            <Divider className=" bg-black" />
            <Row style={{ width: "100%" }}>
                <Col md={12}>
                    <div className="sr-mail-container">
                        <div className="sr-mail-inner">
                            <EmailImg className='email-icon-svg' width={40} />
                            <p>
                                {/* Send ticket details on Email */}
                                {t("subscribeForTickets")}
                                </p>
                        </div>
                        <Form.Item name="subscribe" initialValue={receiver?.subscribe ? receiver?.subscribe : false} rules={[{ required: true }]} className='switch-subscribe' >
                            <Switch size="default" checked={receiver?.subscribe} onChange={(value) => dispatch(editReceiver({
                                key: "subscribe", value
                            }))} className="sr-switch" />
                        </Form.Item>
                    </div>
                </Col>
            </Row>
        </Form>
    )
}

export default Receiver