import React, { useEffect, useState } from "react";
import { Divider, Form, Input, InputNumber, Select, Switch } from "antd";
import { Col, Row } from "react-bootstrap";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { useDispatch, useSelector } from "react-redux";
import { editSender } from "../../../../slices/cart";
import { ReactComponent as EmailImg } from '../../../../assets/img/icon_mail.svg';
import "../../common.css";
import moment from "moment";
import { isPossiblePhoneNumber, isValidPhoneNumber } from "libphonenumber-js";
import { useTranslation } from "react-i18next";

const Sender = ({ setSDay, setSYear, setSMonth, currentYear, setSCountry, sCountry, trigger, sDay, sMonth, sYear }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [senderPhone, setSenderPhone] = useState('')
  const [senderValid, setSenderValid] = useState(true)
  const sender = useSelector((state) => state.cart.cargo.sender)
  const [senderForm] = Form.useForm();
  useEffect(() => {
    if (isPossiblePhoneNumber(sender.phone, sCountry) && isValidPhoneNumber(sender.phone, sCountry)) {
      setSenderValid(true)
    }
    else { setSenderValid(false) }
  }, [sender.phone, sCountry, senderValid])

  const options = [
    {
      value: "female",
      label: t("female"),
    },
    {
      value: "male",
      label: t("male"),
    }
  ]

  const downOutlinedIcon = () => {
    return (
      <span className='down-outlined-icon'></span>
    )
  }

  return (
    <Form
      name="senderForm"
      style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}
      className="common-form"
      layout="vertical" autoComplete="off"
      form={senderForm}
    >
      <div style={{ width: "100%" }}>
        <Col style={{ width: "100%" }}>
          <Row style={{ width: "100%" }}>
            <Col md={8}>
              <Form.Item name="name" initialValue={sender?.name} label={t("name")} validateStatus={trigger && !sender.name ? "error" : "success"} help={trigger && !sender.name ? t("nameValidation") : ""}
              // rules={[
              //   {
              //     required: true,
              //     message: 'Please input your name!'
              //   },
              // ]}
              >
                <Input placeholder={t("name")} onChange={(e) => dispatch(editSender({
                  key: "name", value: e.target.value
                }))} />
              </Form.Item>

            </Col>
          </Row>
          <Row style={{ width: "100%" }}>
            <Col md={8}>
              <Form.Item
                name="surname" label={t("surname")} initialValue={sender?.surname} validateStatus={trigger && !sender.surname ? "error" : "success"} help={trigger && !sender.surname ? t("surnameValidation") : ""}
              // rules={[{ required: true, message: 'Please input your surname!' }]}
              >
                <Input name="surname" placeholder={t("surname")} onChange={(e) => dispatch(editSender({
                  key: "surname", value: e.target.value
                }))} />
              </Form.Item>
            </Col>
            <Col md={4}>

              <Form.Item
                name="gender" label={t("gender")} initialValue={sender?.gender ? sender?.gender : "male"} validateStatus={trigger && !sender.gender ? "error" : "success"} help={trigger && !sender.gender ? t("genderValidation") : ""}
              // rules={[
              //   {
              //     required: true, message: 'Please choose your gender!'
              //   },
              // ]}
              >
                <Select
                  suffixIcon={downOutlinedIcon()}
                  onChange={(value) => dispatch(editSender({
                    key: "gender", value
                  }))}
                  style={{
                    width: "100%",
                  }}
                  options={options}
                />
              </Form.Item>

            </Col>
          </Row>
          <Row style={{ width: "100%" }} className="align-items-end">

            <Col md={4}>

              <Form.Item
                // rules={[
                //   {
                //     required: true, message: 'Please input your day of birth!'

                //   },
                // ]} 
                initialValue={+moment(sender?.birthday).format('D')} name="day" label={t("dob")} validateStatus={trigger && !sDay ? "error" : "success"} help={trigger && !sDay && !sender?.birthday ? t("dobValidation") : ""}>
                <InputNumber placeholder="DD" style={{ width: "100%" }} onChange={(value) => setSDay(value)} min={1} max={31} type='number' />
              </Form.Item>
            </Col>

            <Col md={4}>
              <Form.Item
                // rules={[
                //   {
                //     required: true, message: 'Please input your month of birth!'
                //   },
                // ]} 
                initialValue={+moment(sender?.birthday).format('M')} name="month" validateStatus={trigger && !sMonth && !sender?.birthday ? "error" : "success"} >
                <InputNumber placeholder="MM" style={{ width: "100%" }} onChange={(value) => setSMonth(value)} min={1} max={12} type='number' />
              </Form.Item>
            </Col>

            <Col md={4}>
              <Form.Item
                // rules={[
                //   {
                //     required: true, message: 'Please input your year of birth!'
                //   },
                // ]} 
                initialValue={+moment(sender?.birthday).format('YYYY')} name="year" validateStatus={trigger && !sYear && !sender?.birthday ? "error" : "success"} >
                <InputNumber placeholder="YYYY" style={{ width: "100%" }} onChange={(value) => setSYear(value)} min={1920} max={currentYear} type='number' />
              </Form.Item>
            </Col>

          </Row>
          <Row style={{ width: "100%" }}>
            <Col md={6}>

              <Form.Item
                // rules={[
                //   {
                //     required: true, message: 'Please input your passport ID!'
                //   },
                // ]}
                initialValue={sender?.passport} name="passport" label={t("passport")} validateStatus={trigger && !sender.passport ? "error" : "success"} help={trigger && !sender.passport ? t("passportValidation") : ""}>
                <Input placeholder={t("passport")} onChange={(e) => dispatch(editSender({
                  key: "passport", value: e.target.value
                }))} />
              </Form.Item>

            </Col>
            <Col md={6}>

              <Form.Item initialValue={sender?.phone}
                validateStatus={"error"}
                help={trigger && !senderValid ? t("phoneValidation") : ''}
                // rules={[
                //   {
                //     required: true, message: 'Please input your phone!'
                //   },
                // ]} 
                name="phone" label={t("mphone")}>
                <PhoneInput
                  className={trigger && !senderValid ? 'phone-not-valid' : ''}
                  placeholder="+123 (12) 34-56-78"
                  defaultCountry='md'
                  value={sender?.phone}
                  onChange={(phone, country) => {
                    setSCountry(country)
                    setSenderPhone(phone)
                    dispatch(editSender({
                      key: "phone", value: phone
                    }))
                  }}
                />
              </Form.Item>

            </Col>
          </Row>
          <Row style={{ width: "100%" }}>
            <Col md={6}>

              <Form.Item
                // rules={[
                //   {
                //     required: true, message: 'Please input your e-mail!', type: "email"
                //   },
                // ]} 
                name="email" label={t("email")} initialValue={sender?.email} validateStatus={trigger && !sender.email ? "error" : "success"} help={trigger && !sender.email ? t("emailValidation") : ""}>
                <Input placeholder={t("email")} onChange={(e) => dispatch(editSender({
                  key: "email", value: e.target.value
                }))} />
              </Form.Item>

            </Col>

          </Row>
        </Col>
      </div>
      <Divider className=" bg-black" />
      <Row style={{ width: "100%" }}>
        <Col md={12}>
          <div className="sr-mail-container">
            <div className="sr-mail-inner">
              <EmailImg className='email-icon-svg' width={40} />
              <p>
                {/* Send ticket details on Email */}
                {t("subscribeForTickets")}
                </p>
            </div>
            <Form.Item name="subscribe" initialValue={sender.subscribe ? sender.subscribe : false} rules={[{ required: true }]} className='switch-subscribe' >
              <Switch size="default" checked={sender.subscribe ? sender.subscribe : false} onChange={(value) => dispatch(editSender({
                key: "subscribe", value
              }))} className="sr-switch" />
            </Form.Item>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default Sender