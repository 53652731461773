import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import "./cargo.css"
import CargoForm from "./CargoForm";
import { Button, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { decreaseCargoItems, increaseCargoItems } from "../../../slices/cart";
import { CaretLeftOutlined, CaretRightOutlined, CheckOutlined, ExclamationOutlined } from "@ant-design/icons";

import { ReactComponent as CargoSolid } from "../../../assets/img/cargo_solid.svg";
import { ReactComponent as CargoFragile } from "../../../assets/img/cargo_fragil.svg";
import { useTranslation } from "react-i18next";

const Cargo = ({ setSubmittable, trigger }) => {
    const dispatch = useDispatch();
    const cargoItems = useSelector(state => state.cart.cargo.items);
    const [selectedTab, setSelectedTab] = useState(0)
    const items = [];
    const { t } = useTranslation()

    const checkSubmittable = (index) => {
        if (!Object.values(cargoItems[index]).some(item => item == null || item == '' || item == undefined || item == 0)) {
            return true
        }
        return false
    }

    const checkValidity = (index) => {
        if (trigger & !checkSubmittable(index)) {
            return true
        }
        return false
    }

    cargoItems.map((el, index) => items.push({
        key: index,
        label: (<span className="cargo-tab-item"> {t("cargo")} {index + 1} {checkSubmittable(index) ? <span className="cargo-tab-tick cargo-tab-tick-appear"><CheckOutlined /></span> : <span className="cargo-tab-tick-disappear"><CheckOutlined /></span>} {checkValidity(index) ? <span className="cargo-tab-tick cargo-tab-tick-appear bg-danger"><ExclamationOutlined /></span> : <span className="cargo-tab-tick-disappear"><ExclamationOutlined /></span>} </span>),
        children: <CargoForm index={index} item={el} trigger={trigger} />,
        isValid: checkSubmittable(index) ? true : false
    }))

    useEffect(() => {
        if (items.every((item => item.isValid === true))) {
            setSubmittable(true)
        } else setSubmittable(false)
    }, [items])

    const OperationsSlot = {
        left: <Button className="common-arrow-btn" onClick={() => {
            if (selectedTab > 0)
                setSelectedTab(selectedTab - 1)
        }}><CaretLeftOutlined style={{ color: '#ffffff', fontSize: "26px" }} type="message" /></Button>,
        right: <Button className="common-arrow-btn" onClick={() => {
            if (selectedTab < cargoItems.length - 1)
                setSelectedTab(selectedTab + 1)
        }}><CaretRightOutlined style={{ color: '#ffffff', fontSize: "26px" }} type="message" /></Button>,
    };

    useEffect(() => {
        if (selectedTab > cargoItems.length - 1) {
            setSelectedTab(cargoItems.length - 1)
        }
    }, [cargoItems]);

    return (
        <Container className="cargo-outer py-3">
            <Row>
                <Col>
                    <div className="cargo-type-container">
                        <div className="cargo-type-slider" >
                            <CargoSolid className="cargo-type-icon cargo-type-solid" style={cargoItems[selectedTab]?.type == 1 ? { transform: "translateY(-36px)", transition: "ease-in-out 1s" } : { transform: "translateY(0px)", transition: "ease-in-out 1s" }} />
                            <CargoFragile className="cargo-type-icon" style={cargoItems[selectedTab]?.type == 1 ? { transform: "translateY(-36px)", transition: "ease-in-out 1s" } : { transform: "translateY(0px)", transition: "ease-in-out 1s" }} />
                        </div>
                        <p>
                            {/* Cargo Quantity */}
                            {t("cargQty")}
                        </p>
                    </div>
                </Col>
                <Col className='common-count-btn d-flex align-items-center justify-content-end'>
                    <Button onClick={() => {
                        dispatch(decreaseCargoItems())
                    }}> - </Button>
                    <span className='count-bag'>{items.length}</span>
                    <Button onClick={() => {
                        dispatch(increaseCargoItems());
                    }}> + </Button>
                </Col>
            </Row>
            <Tabs
                activeKey={selectedTab}
                tabBarExtraContent={OperationsSlot}
                centered={cargoItems.length > 4 ? false : true}
                type="tab"
                onTabClick={(aa) => {
                    setSelectedTab(aa)
                }}
                defaultActiveKey={1}
                items={items} />
        </Container>)
}
export default Cargo;
