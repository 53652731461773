import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import apiService from "../services/api.service";

const initialState = {
    locations: [],
    status: 'idle',
    error: null,
}

export const fetchLocations = createAsyncThunk('locations/fetchList', async (data) => {
    const response = await apiService.getLocationsList();
    return response.data
})



const locationsSlice = createSlice({
    name: 'locations',
    initialState,
    reducers: {

    },
    extraReducers(builder) {
        builder
            .addCase(fetchLocations.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // Add any fetched posts to the array
                state.locations = action.payload
            })
    },
})

// export const { postAdded, postUpdated, reactionAdded } = stationsSlice.actions

export default locationsSlice.reducer

export const getAllLocations = (state) => state.locations.locations

// export const selectPostById = (state, postId) =>
//     state.posts.posts.find((post) => post.id === postId)

