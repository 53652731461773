import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CargoFragile } from '../../../assets/img/cargo_fragil.svg';
import { ReactComponent as CargoSolid } from '../../../assets/img/cargo_solid.svg';
import { Divider } from 'antd';
import PaymentSwitcher from '../../payment/PaymentSwitcher';
import { editSender, setPaymentType } from '../../../slices/cart';
import { useTranslation } from 'react-i18next';

const CargoConfirm = ({ setSubmittable, trigger }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const cargoItems = useSelector((state) => state.cart.cargo.items)
  const cargoSender = useSelector((state) => state.cart.cargo.sender)
  const cargoReceiver = useSelector((state) => state.cart.cargo.receiver)
  const payment = useSelector((state) => state.cart.payment)

  const [check, setCheck] = useState(false);


  useEffect(() => {
    if (check && payment) {
      setSubmittable(true)
    }
    else { setSubmittable(false) }
  }, [check, payment])


  const onChange = (e) => {
    dispatch(setPaymentType(e.target.value))
  };

  return (
    <Container className="cargo-outer min-h-100">
      <Row>
        <Col md={4}>
          <div className='d-flex flex-column'>
            {cargoItems.map((item, i) =>
              <div key={i} className='d-flex align-items-center cargo-outer-gap cargo-outer-row'>
                {item.type == 1
                  ? (<div className='cargo-fragil-img'><CargoFragile height={52} /></div>)
                  : (<CargoSolid height={52} />)
                }
                <div className='cargo-confirm-item-info'>
                  <h3>
                    {/* Cargo  */}
                    {t("cargo")} {" "}
                    {i + 1}
                  </h3>
                  <h4>{t("weight")}: {item.weight} Kg</h4>
                  <h3>{item.type == 1 ? t("fragile") : t("solid")}</h3>
                </div>
              </div>
            )}
          </div>
        </Col>
        <Col md={4}>
          <div className='cargo-confirm-item-info'>
            <h3>{t("sender")}</h3>
            <h4>{cargoSender.name} {cargoSender.surname}</h4>
            <h3>{cargoSender.phone}</h3>
          </div>
        </Col>
        <Col md={4}>
          <div className='cargo-confirm-item-info'>
            <h3>{t("receiver")}</h3>
            <h4>{cargoReceiver.name} {cargoReceiver.surname}</h4>
            <h3>{cargoReceiver.phone}</h3>
          </div>
        </Col>
      </Row>

      <Divider style={{ background: '#DFDFE6', height: '2px' }} />

      <PaymentSwitcher check={check} onChange={onChange} setCheck={setCheck} payment={payment} trigger={trigger} />

    </Container>
  )
}

export default CargoConfirm