import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from '@reduxjs/toolkit'

import { setupListeners } from '@reduxjs/toolkit/query'
import locationsReducer from "./locations"
import searchReducer from "./search"
import cartReducer from "./cart"
import storage from 'redux-persist/lib/storage/session'
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'
const persistConfig = {
    key: 'root',
    storage: storage
}
export const appReducer = combineReducers({
    locations: locationsReducer,
    search: searchReducer,
    cart: cartReducer
})

const rootReducers = (state, action) => {
    if (action.type === 'search/resetCart') {
        storage.removeItem('persist:root')
        state.cart = undefined
    }
    return appReducer(state, action);
};
const persistedReducer = persistReducer(persistConfig, rootReducers);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    })
})
setupListeners(store.dispatch)
export default store

// export const store = configureStore({
//     reducer: {
//         locations: locationsReducer,
//         search: searchReducer,
//         cart: cartReducer
//     },
// })
