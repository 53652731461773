import React, { useEffect } from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Form, InputNumber, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { editCargoItem } from "../../../slices/cart";
import "./cargo.css";
import "../common.css";
import { useTranslation } from 'react-i18next';

const CargoForm = ({ item, index, trigger }) => {
    const dispatch = useDispatch();
    const options = [];
    const trip = useSelector(state => state.cart.trip);
    trip?.prices.map(el => options.push({ value: el.id, label: el.title }));
    const [form] = Form.useForm();
    const { t } = useTranslation()

    const downOutlinedIcon = () => {
        return (
            <span className='down-outlined-icon'></span>
        )
    }

    return (<>
        <Form name="cargo-form" className="common-form" form={form} layout="vertical" autoComplete="off">
            <Row className="d-flex my-4">
                <Col md={4}>
                    <Form.Item name="type" initialValue={item?.type} label={t("type")} validateStatus={trigger && !item.type ? "error" : "success"} help={trigger && !item.type ? '"Type" is required' : ""}
                        rules={[
                            {
                                required: true,
                                message: '"Type" is required'
                            },
                        ]}
                    >
                        <Select
                            suffixIcon={downOutlinedIcon()}
                            defaultActiveFirstOption={true}
                            style={{
                                width: "100%",
                            }}
                            onChange={(value) => {
                                dispatch(editCargoItem({
                                    index,
                                    key: 'type',
                                    value
                                }))
                            }}
                            options={options}
                        />
                    </Form.Item>
                </Col>
                <Col md={2}>
                    <Form.Item name="weight" initialValue={item?.weight} label={t("weight")} validateStatus={trigger && !item.weight ? "error" : "success"} help={trigger && !item.weight ? '"Weight" is required' : ""}
                        rules={[
                            {
                                required: true,
                                message: '"Weight" is required'
                            },
                        ]}
                    >
                        <InputNumber
                            min={0}
                            placeholder="kg"
                            onChange={(el) => {
                                dispatch(editCargoItem({
                                    index,
                                    key: 'weight',
                                    value: el
                                }))
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col md={2}>
                    <Form.Item name="length" initialValue={item?.length} label={t("length")} validateStatus={trigger && !item.length ? "error" : "success"} help={trigger && !item.length ? '"Length" is required' : ""}
                        rules={[
                            {
                                required: true,
                                message: '"Length" is required'
                            },
                        ]}
                    >
                        <InputNumber
                            min={0}
                            placeholder="cm"
                            onChange={(el) => {
                                dispatch(editCargoItem({
                                    index,
                                    key: 'length',
                                    value: el
                                }))
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col md={2}>
                    <Form.Item name="width" initialValue={item?.width} label={t("width")} validateStatus={trigger && !item.width ? "error" : "success"} help={trigger && !item.width ? '"Width" is required' : ""}
                        rules={[
                            {
                                required: true,
                                message: '"Width" is required'
                            },
                        ]}
                    >
                        <InputNumber
                            min={0}
                            placeholder="cm"
                            onChange={(el) => {
                                dispatch(editCargoItem({
                                    index,
                                    key: 'width',
                                    value: el
                                }))
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col md={2}>
                    <Form.Item name="height" initialValue={item.height} label={t("height")} validateStatus={trigger && !item.height ? "error" : "success"} help={trigger && !item.height ? '"Height" is required' : ""}
                        rules={[
                            {
                                required: true,
                                message: '"Height" is required'
                            },
                        ]}
                    >
                        <InputNumber
                            min={0}
                            placeholder="cm"
                            onChange={(el) => {
                                dispatch(editCargoItem({
                                    index,
                                    key: 'height',
                                    value: el
                                }))
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </>)
}
export default CargoForm
