import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import apiService from "../services/api.service";
import {fetchLocations} from "./locations";

const initialState = {
    from:null,
    to: null,
    date: '',
    count:1,
    results:[],
    arrivalResult: [],
    departureResult: [],
    status: 'idle',
    error: null,
    type: 'tickets',
}


export const fetchSearch = createAsyncThunk('search/fetchSearch', async (data, {getState}) => {
    const state = getState();
    const response = await apiService.fetchSearch(data, state.search.type);
    return response.data
})

export const fetchArrival = createAsyncThunk('search/fetchArrival', async (data) => {
    const resp = await apiService.fetchArrival(data);
    return resp.data
})
export const fetchDeparture = createAsyncThunk('search/fetchDeparture', async (data) => {
    const resp = await apiService.fetchDeparture(data);
    return resp.data
})

const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        resetCart(state, action) {

        },
        setFrom(state,action) {
            state.from = action.payload;
        },
        setTo(state,action) {
            state.to = action.payload;
        },
        setDate(state,action) {
            state.date = action.payload
        },
        setStatus(state,action) {
            state.status = action.payload
        },
        setTicketType(state, action) {
            state.type = action.payload
        },
        clearResult(state, action) {
            state.results = action.payload
        },
        clearArrivals(state, action) {
            state.arrivalResult = action.payload
        },
        clearDepartures(state, action) {
            state.departureResult = action.payload
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchSearch.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // Add any fetched posts to the array
                state.results = action.payload
            })
        .addCase(fetchArrival.fulfilled, (state, action) => {
            state.status = 'succeeded'
            // Add any fetched posts to the array
            state.arrivalResult = action.payload
        })
        .addCase(fetchDeparture.fulfilled, (state, action) => {
            state.status = 'succeeded'
            // Add any fetched posts to the array
            state.departureResult = action.payload
        })
    },
})


export default searchSlice.reducer

export const {resetCart, setFrom, setTo, setDate, setStatus, setTicketType, clearResult, clearArrivals, clearDepartures } = searchSlice.actions

// export const selectPostById = (state, postId) =>
//     state.posts.posts.find((post) => post.id === postId)

